.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-clickable-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.popup-inner .close-btn {
  display: inline-block;
  padding: 0.25em 0.61em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  border-width: 2px;
  border-style: solid;
  border-color: lightgray;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: lightgray;
  background-color: white;
  text-align: center;
  transition: all 0.2s;
  position: absolute;
  right: -17px;
  top: -7px;
  font-size: x-large;
}

.popup-inner .close-btn:hover {
  border-color: darkgray;
  color: darkgray;
  cursor: pointer;
}

@media all and (max-width: 30em) {
  .popup-inner .close-btn {
    display: block;
    margin: 0.2em auto;
  }
}
